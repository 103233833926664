import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderFourth } from 'components/header-fourth';
import { MainTitle } from 'components/main-title';
import { PageBackground } from 'components/page-background';
import { Paragraph } from 'components/paragraph';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';
import { bool, func, node, object, shape, string } from 'prop-types';
import {
    onGetYourConsultancyClick,
    scrollIntoView,
    trackCustomEventGoogleAnalytics,
} from 'utilities/index';

const SWrapper = styled(Container)`
    width: 100%;
    margin-bottom: 10rem;
    ${({ wrapperCss }) => wrapperCss}
`;

const SProjectsMainSection = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    padding-top: ${CONSTANTS.MARGIN.PAGE};
    ${({ isBoxStyle }) =>
        isBoxStyle &&
        css`
            background-color: var(--gray-color-90);
            width: 100%;
            padding: 5rem var(--section-clamp) 4rem;
            margin-top: 9.75rem;
            ${CONSTANTS.MEDIA.max1024`
                margin-top: 5rem;
                padding: 1rem 2rem 0;
            `}
            ${CONSTANTS.MEDIA.max500`
                padding: 1rem 1rem 0;
            `}
        `}
`;

const SProjectsSectionWrapper = styled.div`
    text-align: center;
    padding: 3rem ${CONSTANTS.MARGIN.HORIZONTAL} 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${CONSTANTS.MEDIA.max1024`
        padding: 1rem;
    `}
    ${({ isTopSubTitle }) =>
        isTopSubTitle &&
        `
        padding: 1rem 1rem 0;
    `}
`;

const SSubtitle = styled(Paragraph)`
    font-size: 1.4rem;
    position: relative;
    margin-bottom: 4.688rem;
    text-align: center;
    font-weight: 400;
    ${({ subTitleCss }) => subTitleCss};
`;

const SButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    ${({ isTopSubTitle }) =>
        isTopSubTitle &&
        `
        margin-bottom: 0;
    `}
`;

const SButton = styled(Button)`
    margin: 0 1rem 1rem 1rem;
    min-width: 16.375rem;
`;

const SHeaderFourth = styled(HeaderFourth)`
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-bottom: 1rem;
`;

const STitle = styled(MainTitle)`
    margin: 0 3rem 3rem;
    text-align: center;
    white-space: pre-wrap;
    ${CONSTANTS.MEDIA.max1024`
        margin: 0 0.5rem 0.5rem;
    `}
    ${({ isTopSubTitle }) =>
        isTopSubTitle &&
        `
        margin: 0 0 1.5rem;
        &:after { 
            display: none;
        }
    `}
`;

export const Specification = ({
    texts: { topSubTitle, title, subtitle, buttonFirst, buttonSecond },
    background,
    projectId,
    googleAnalyticsConsultancy,
    googleAnalyticsProjects,
    className,
    isBoxStyle,
    firstBtnOptions,
    secondBtnOptions,
    subTitleCss,
    isSubtitleFormatted,
    wrapperCss,
    customButtonsComponent,
}) => {
    const {
        navigateTo: firstNavigateTo,
        handleClick: firstHandleClick,
        component: firstComponent,
    } = firstBtnOptions;
    const {
        navigateTo: secondNavigateTo,
        handleClick: secondHandleClick,
        component: secondComponent,
    } = secondBtnOptions;

    const onGetYourConsultancy = () => {
        onGetYourConsultancyClick(googleAnalyticsConsultancy);
    };
    const showAllProjects = (event) => {
        event.preventDefault();
        scrollIntoView(projectId);
        trackCustomEventGoogleAnalytics(googleAnalyticsProjects);
    };

    const childImageSharp = background?.desktop?.childImageSharp;
    const isTopSubTitle = !!topSubTitle;

    return (
        <SWrapper isBoxStyle={isBoxStyle} wrapperCss={wrapperCss}>
            <SProjectsMainSection className={className} isBoxStyle={isBoxStyle}>
                {childImageSharp && (
                    <PageBackground
                        childImageSharp={childImageSharp}
                        alt="Specification Page Background"
                    />
                )}
                <SProjectsSectionWrapper isTopSubTitle={isTopSubTitle}>
                    {topSubTitle && (
                        <SHeaderFourth>
                            <FormattedMessage id={topSubTitle} />
                        </SHeaderFourth>
                    )}
                    {title && (
                        <STitle isTopSubTitle={isTopSubTitle}>
                            <FormattedMessage id={title} />
                        </STitle>
                    )}
                    {subtitle && (
                        <SSubtitle subTitleCss={subTitleCss}>
                            {isSubtitleFormatted ? (
                                subtitle
                            ) : (
                                <FormattedMessage id={subtitle} />
                            )}
                        </SSubtitle>
                    )}
                    <SButtons isTopSubTitle={isTopSubTitle}>
                        {customButtonsComponent || (
                            <>
                                {buttonFirst && (
                                    <SButton
                                        component={
                                            firstComponent || 'gatsby-link'
                                        }
                                        to={firstNavigateTo || PATHS.CONTACT_US}
                                        isFilled
                                        onClick={
                                            firstHandleClick ||
                                            onGetYourConsultancy
                                        }
                                    >
                                        <FormattedMessage id={buttonFirst} />
                                    </SButton>
                                )}
                                {buttonSecond && (
                                    <SButton
                                        variant="outline"
                                        component={
                                            secondComponent || 'gatsby-link'
                                        }
                                        to={secondNavigateTo}
                                        onClick={
                                            secondHandleClick || showAllProjects
                                        }
                                    >
                                        <FormattedMessage id={buttonSecond} />
                                    </SButton>
                                )}
                            </>
                        )}
                    </SButtons>
                </SProjectsSectionWrapper>
            </SProjectsMainSection>
        </SWrapper>
    );
};

Specification.propTypes = {
    background: object,
    projectId: string.isRequired,
    texts: object.isRequired,
    googleAnalyticsConsultancy: shape({
        category: string,
        action: string,
    }).isRequired,
    googleAnalyticsProjects: shape({
        category: string,
        action: string,
    }).isRequired,
    className: string,
    isBoxStyle: bool,
    firstBtnOptions: shape({
        navigateTo: string,
        handleClick: func,
        component: string,
    }),
    secondBtnOptions: shape({
        navigateTo: string,
        handleClick: func,
        component: string,
    }),
    wrapperCss: string,
    customButtonsComponent: node,
};

Specification.defaultProps = {
    className: '',
    background: {},
    isBoxStyle: false,
    firstBtnOptions: {},
    secondBtnOptions: {},
};
