import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'components/container';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';
import { arrayOf, bool, string } from 'prop-types';

export const WorkingWithUsSection = ({
    isReversed,
    title,
    descriptions,
    alt,
}) => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            background: file(
                relativePath: { eq: "team/working-with-team.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <RowWithTextImg
                title={title}
                descriptions={descriptions}
                img={images.background}
                isReversed={isReversed}
                alt={formatMessage({ id: alt })}
                isDescriptionFormattedPreviously
            />
        </Container>
    );
};

WorkingWithUsSection.propTypes = {
    descriptions: arrayOf(string).isRequired,
    alt: string.isRequired,
    title: string,
    isReversed: bool,
};

WorkingWithUsSection.defaultProps = {
    isReversed: false,
    title: '',
};
