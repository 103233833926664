import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { Arrow } from 'components/arrow';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bool, node, object, oneOfType, string } from 'prop-types';
import { isGatsbyImage } from 'utilities';

const defaultLinkStyles = css`
    text-decoration: none;
    color: inherit;
`;

const SPrefixLangLink = styled(PrefixLangLink)`
    ${defaultLinkStyles}
`;

const SExternalLink = styled.a`
    ${defaultLinkStyles}
`;

const SSection = styled.section`
    padding: 4.125rem 5.625rem;
    display: flex;
    gap: 5.5rem;
    flex-direction: ${({ isReversed }) => isReversed && 'row-reverse'};
    ${({ sectionCustomStyles }) => ({ ...sectionCustomStyles })};

    ${({ isDark, order }) => {
        if (isDark) {
            return css`
                background-color: var(--secondary-color);
            `;
        } else if (order === 'odd') {
            return css`
                &:nth-child(odd) {
                    background-color: var(--gray-color-90);
                }
            `;
        } else if (order === 'even') {
            return css`
                &:nth-child(even) {
                    background-color: var(--gray-color-90);
                }
            `;
        }
    }}}
    ${CONSTANTS.MEDIA.max1024`
        padding: 2rem;
        flex-direction: column-reverse;
        gap: 2rem;
        align-items: center;
    `}
`;

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: center;
    text-align: ${({ isReversed }) => (isReversed ? 'end' : 'start')};
    align-items: ${({ isReversed }) =>
        isReversed ? 'flex-end' : 'flex-start'};

    ${CONSTANTS.MEDIA.max1024`
        text-align: center;
        align-items: center;
    `}
`;

const SArrow = styled(Arrow)`
    ${SSection}:hover & {
        .bg-path {
            fill: var(--primary-color);
        }
        .arrow {
            stroke: var(--white-color);
        }
    }
`;

const MediaWrapperStyles = css`
    flex: 0.5;
    width: 100%;
    max-width: 28.125rem;
    max-height: 23.75rem;
`;

const SIconWrapper = styled(SWrapper)`
    ${MediaWrapperStyles};
    ${({ graphicsWrapperCustomStyles }) => ({
        ...graphicsWrapperCustomStyles,
    })};
`;

const SImage = styled(GatsbyImage)`
    ${MediaWrapperStyles};
    ${({ graphicsWrapperCustomStyles }) => ({
        ...graphicsWrapperCustomStyles,
    })};
`;

const STitle = styled(HeaderSecond)`
    font-size: 2.25rem;
    margin-bottom: 1.25rem;
    ${({ isDark }) =>
        isDark &&
        css`
            color: var(--white-color);
        `}
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 2rem;
    ${({ isDark }) =>
        isDark &&
        css`
            color: var(--white-color);
        `}
`;

export const SingleService = ({
    title,
    description,
    url,
    isReversed,
    img,
    alt,
    isDark,
    order,
    sectionCustomStyles,
    graphicsWrapperCustomStyles,
    isExternalLink,
}) => {
    const { formatMessage } = useIntl();

    const resolveImage = () => {
        if (!img) {
            return null;
        }

        if (isGatsbyImage(img)) {
            return (
                <SImage
                    image={img.childImageSharp.gatsbyImageData}
                    imgStyle={{
                        objectFit: 'scale-down',
                        objectPosition: 'contain',
                        width: '100%',
                    }}
                    graphicsWrapperCustomStyles={graphicsWrapperCustomStyles}
                    alt={alt}
                />
            );
        }

        return (
            <SIconWrapper
                graphicsWrapperCustomStyles={graphicsWrapperCustomStyles}
            >
                {img}
            </SIconWrapper>
        );
    };

    const content = (
        <SSection
            isReversed={isReversed}
            isDark={isDark}
            order={order}
            sectionCustomStyles={sectionCustomStyles}
        >
            {resolveImage()}
            <SWrapper isReversed={isReversed}>
                <STitle isDark={isDark}>
                    <FormattedMessage id={title} />
                </STitle>
                <SParagraph isDark={isDark}>
                    <FormattedMessage id={description} />
                </SParagraph>
                <SArrow />
            </SWrapper>
        </SSection>
    );

    if (isExternalLink) {
        return (
            <SExternalLink href={url} target="_blank" rel="noopener">
                {content}
            </SExternalLink>
        );
    }

    return (
        <SPrefixLangLink to={url} aria-label={formatMessage({ id: title })}>
            {content}
        </SPrefixLangLink>
    );
};

SingleService.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
    url: string.isRequired,
    isReversed: bool,
    isDark: bool,
    img: oneOfType([node.isRequired, object.isRequired, null]),
    order: string,
    sectionCustomStyles: object,
    graphicsWrapperCustomStyles: object,
    isExternalLink: bool,
};

SingleService.defaultProps = {
    isReversed: false,
    isDark: false,
    order: 'odd',
    isExternalLink: false,
};
